import React, { Component } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "src/layouts"
import { config } from "data"
import { Tags } from "src/components"
// import Kudos from 'kudos';
// import 'kudos/dist/kudos.css';
import urljoin from "url-join"

export default class PostTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideKudos: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll)
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.setState({ hideKudos: true })
    } else {
      this.setState({ hideKudos: false })
    }
  }

  createSchema(post, slug, image) {
    const schema = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url: config.siteUrl,
        name: config.siteTitle,
      },
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        url: urljoin(config.siteUrl, `/posts/${slug}`),
        name: post.title,
        headline: post.title,
        image: {
          "@type": "ImageObject",
          url: image,
        },
        description: post.description,
      },
    ]
    return JSON.stringify(schema)
  }
  // eslint-disable-next-line class-methods-use-this
  handleKudos() {}

  render() {
    const postNode = this.props.data.markdownRemark
    const slug = postNode.fields.slug
    const post = postNode.frontmatter
    const date = post.date
    // eslint-disable-next-line no-unused-vars
    const { hideKudos } = this.state
    const readingTime = postNode.fields.readingTime.text
    const image = urljoin(
      config.siteUrl,
      `/${post.thumbnail.childImageSharp.fixed.src}`
    )
    const schema = this.createSchema(post, slug, image)

    return (
      <Layout>
        <Helmet>
          <title>{`${post.title} – ${config.siteTitle}`}</title>
          <meta name="description" content={post.description} />
          <meta name="image" content={image} />
          <meta
            property="og:url"
            content={urljoin(config.siteUrl, `/posts/${slug}`)}
          />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:image" content={image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={config.userTwitter} />
          <meta name="twitter:title" content={post.title} />
          <meta name="twitter:description" content={post.description} />
          <meta name="twitter:image" content={image} />
          <script type="application/ld+json">{schema}</script>
        </Helmet>
        <header className="post-meta">
          <time className="post-date">{date}</time>
          <span className="post-reading-time">{readingTime}</span>
          <h1 className="post-title">{post.title}</h1>
          <p className="post-description">{post.description}</p>

          <Tags tags={post.tags} />
        </header>
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: postNode.html }}
        />
        {/* {!hideKudos &&

          <div className="kudos-vertical-container">
            <Kudos count={2000} onCompleted={this.handleKudos.bind(this)}></Kudos>
          </div>
        }
        <div className="kudos-horizontal-container">
          <Kudos count={2000} orientation={"horizontal"} onCompleted={this.handleKudos.bind(this)}></Kudos>
        </div> */}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        description
        thumbnail {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        date(formatString: "MMMM Do, YYYY")
        categories
        tags
        template
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`
